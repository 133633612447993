<template>
  <div class="expect-container">
    <div class="title">【計画】あなたのチームにおける役割は踏まえて記載する</div>
    <div class="monthly-goal" @click.stop="openEditor('monthlyGoal')">
      <div class="text">【当月の目標を記載】（数量化して達成の可否判断ができるように記載）</div>
      <div class="content">
        <el-popover placement="bottom-start" width="auto" trigger="hover" :offset="-1">
          <div v-html="placesHolder(planSate.monthlyGoal, placesHolderActionHtml, planSate.isChanged)"></div>
          <template #reference>
            <div>
              <div
                v-if="!planSate.monthlyGoal && !planSate.isChanged"
                class="paragraph"
                v-html="placesHolderActionHtml"
              ></div>
              <div
                v-else
                class="paragraph"
                v-for="(item, index) in parseTags(planSate.monthlyGoal)"
                :key="index"
                v-html="item"
              ></div>
            </div>
          </template>
        </el-popover>
      </div>
    </div>
    <div class="row">
      <div class="monthly-goal-action" @click.stop="openEditor('monthlyGoalAction')">
        <div class="text">【目標を達成に必要な行動を記載】（数量化して達成の可否判断ができるように記載）
        </div>
        <div class="content">
          <el-popover placement="bottom-start" width="auto" trigger="hover" :offset="-1">
            <div v-html="placesHolder(planSate.monthlyGoalAction, placesHolderActionHtml, planSate.isChanged)"></div>
            <template #reference>
              <div>
                <div
                  v-if="!planSate.monthlyGoalAction && !planSate.isChanged"
                  class="paragraph"
                  v-html="placesHolderActionHtml"
                ></div>
                <div
                  v-else
                  class="paragraph"
                  v-for="(item, index) in parseTags(planSate.monthlyGoalAction)"
                  :key="index"
                  v-html="item"
                ></div>
              </div>
            </template>
          </el-popover>
        </div>
      </div>
<!--      <div class="monthly-goal-action-amount" @click.stop="openEditor('monthlyGoalActionAmount')">-->
<!--        <div class="text">【行動の数量（ゴール）の設定】</div>-->
<!--        <div class="content">-->
<!--          <el-popover placement="bottom-start" width="auto" trigger="hover" :offset="-1">-->
<!--            <div v-html="planSate.monthlyGoalActionAmount"></div>-->
<!--            <template #reference>-->
<!--              <div>-->
<!--                <div-->
<!--                  class="paragraph"-->
<!--                  v-for="(item, index) in parseTags(planSate.monthlyGoalActionAmount)"-->
<!--                  :key="index"-->
<!--                  v-html="item"-->
<!--                ></div>-->
<!--              </div>-->
<!--            </template>-->
<!--          </el-popover>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="monthly-goal-point" @click.stop="openEditor('monthlyGoalPoint')">
      <div class="text">
        【行動を達成するために重要となる点を記載】
      </div>
      <div class="content" style="position: relative">
        <el-popover placement="bottom-start" width="auto" trigger="hover" :offset="-1">
          <div v-html="placesHolder(planSate.monthlyGoalPoint, placesHolderActionHtml, planSate.isChanged)"></div>
          <template #reference>
            <div>
              <div
                v-if="!planSate.monthlyGoalPoint && !planSate.isChanged"
                class="paragraph"
                v-html="placesHolderActionHtml"
              ></div>
              <div
                v-else
                class="paragraph"
                v-for="(item, index) in parseTags(planSate.monthlyGoalPoint)"
                :key="index"
                v-html="item"
              ></div>
            </div>
          </template>
        </el-popover>
        <div class="post-info">
          <div
            v-if="planSate.updatedAt"
            style="
              border: 1px dashed darkslategrey;
              line-height: 12px;
              padding: 2px;
              margin-bottom: 2px;
              background-color: lightcyan;
            "
          >
            {{ planSate.updatedAt }}
          </div>
          <div v-else>
            <el-button
              v-if="isRenderButton"
              size="mini"
              type="primary"
              style="position: absolute; right: 4px; bottom: 4px"
              @click.stop="confirmSubmit"
              >報告</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <EditorDialog v-model:visible="visible" v-model:content="content" @confirm="onConfirm" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)
import { postUserMonthPlan } from '@/api/user'
import { splitTag } from '@/utils/helper'
import EditorDialog from '@/components/common/EditorDialog'
import { ElMessageBox } from 'element-plus'
export default {
  props: {
    date: {
      type: String,
      default: ''
    },
    plans: {
      type: Object,
      default: () => {}
    },
    userId: {
      type: String,
      default: ''
    },
    role: {
      type: String,
      default: ''
    }
  },
  components: {
    EditorDialog
  },
  data() {
    return {
      placesHolderActionHtml:
        '<p>（１）<br/>（２）<br/>（３）</p>',
      planSate: {
        updatedAt: '',
        isChanged: false,
        monthlyGoal: '',
        monthlyGoalAction: '',
        monthlyGoalActionAmount: '',
        monthlyGoalPoint: ''
      },
      visible: false,
      content: '',
      field: ''
    }
  },
  watch: {
    '$props.plans': {
      handler: function (plans) {
        this.planSate = plans
        if (plans.updatedAt) {
          this.planSate.updatedAt = dayjs
            .tz(plans.updatedAt, 'Asia/Tokyo')
            .format('YYYY/MM/DD HH:mm')
        }
      }
    }
  },
  methods: {
    placesHolder(item, holder, isChanged) {
      if (isChanged) return item
      else {
        if (item === '') return holder
        return item
      }
    },
    parseTags(str) {
      let tag = splitTag(str)
      return tag.slice(0, 4)
    },
    openEditor(field) {
      if (this.role !== 'root' && this.role !== 'subRoot') {
        if (this.userId !== this.$store.state.user.userInfo.userId) return false
        if (this.planSate.isChanged === true) return false
      }
      if (
        this.role === 'subRoot' &&
        this.planSate.isChanged === true &&
        this.userId === this.$store.state.user.userInfo.userId
      )
        return false

      if (this.role === 'subRoot' && this.userId === 3) return false
      this.field = field
      if (field === 'monthlyGoal') {
        this.content = this.placesHolder(this.planSate[this.field], this.placesHolderActionHtml)
      } else if (field === 'monthlyGoalAction') {
        this.content = this.placesHolder(this.planSate[this.field], this.placesHolderActionHtml)
      } else if (field === 'monthlyGoalPoint') {
        this.content = this.placesHolder(this.planSate[this.field], this.placesHolderActionHtml)
      } else {
        this.content = this.planSate[this.field]
      }
      this.visible = true
    },
    onConfirm(val) {
      this.planSate[this.field] = val
      this.onSubmit()
      // if(this.role === 'root') {
      // }
    },
    confirmSubmit() {
      ElMessageBox.confirm(
        '入力内容でご報告よろしいですか？報告後、該当内容は再編集出来ませんので、ご注意ください。',
        'ご確認',
        {
          confirmButtonText: '確定',
          cancelButtonText: 'キャンセル',
          type: 'primary'
        }
      )
        .then(() => {
          this.onSubmit(true)
        })
        .catch((e) => {
          console.log(e)
        })
    },
    onSubmit(isAutoLock = false) {
      const data = JSON.parse(JSON.stringify(this.planSate))
      data.userId = this.userId * 1
      data.date = this.date + '-01'
      data.isAutoLock = isAutoLock
      postUserMonthPlan(data).then((res) => {
        this.$emit('updateUser')
      })
    }
  },
  computed: {
    isRenderButton() {
      if (this.userId !== this.$store.state.user.userInfo.userId) {
        return false
      }
      if (this.planSate.isChanged === true) {
        return false
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.expect-container {
  width: 50%;
  .title {
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #00d659;
    font-size: 16px;
    font-weight: bold;
    border-right: 2px solid #000;
    border-bottom: 1px solid #000;
  }
  .text {
    height: 20px;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
  }
  .text1 {
    font-size: 14px;
  }
  .monthly-goal {
    border-right: 2px solid #000;
    border-top: 1px solid #000;
    overflow: hidden;
    .content {
      height: 78px;
      padding-bottom: 4px;
    }
  }
  .row {
    display: flex;
    align-items: center;
  }
  .monthly-goal-action,
  .monthly-goal-action-amount {
    background: #eff8fe;
    border-top: 1px solid #000;
    overflow: hidden;
    .content {
      height: 78px;
      padding-bottom: 4px;
    }
  }
  //.monthly-goal-action {
  //  width: 60%;
  //  border-right: 1px dashed #000;
  //  overflow: hidden;
  //}
  .monthly-goal-action {
    width: 100%;
    border-right: 2px solid #000;
    overflow: hidden;
  }
  .monthly-goal-action-amount {
    width: 40%;
    border-right: 2px solid #000;
  }
  .monthly-goal-point {
    overflow: hidden;
    border-right: 2px solid #000;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    .content {
      height: 78px;
      padding-bottom: 4px;
    }
  }
}
</style>

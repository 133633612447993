import { createApp } from "vue";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CKEditor from "@ckeditor/ckeditor5-vue";

import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
import './style/global.css'
import './theme/index.css'
import App from "./App.vue";
// @ts-ignore
import router from "./router";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from "./store";

createApp(App)
  .use(CKEditor)
  .use(router)
  .use(store)
  .use(ElementPlus, null)
  .mount("#app");
